.Navigation{
  padding: 15px;
  margin-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: 1px lightgray solid;
  border-top: 1px lightgray solid;
}
.NewVoting{
  padding: 30px;
  margin: 10px;
  margin-bottom: 40px;
  font-size: large;
  border-radius: 10px;
}
.VotingBox
{
  padding: 20px;
  margin: 10px;
  box-Shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-Radius: 10px;
  background-Color: white;
  display: flex;
  justify-Content: space-between;
  align-Items: center;
}

.HistoryHeader{
  margin-bottom: 20px;
}

.HistoryTitle{
  font-Size: 18px;
  font-Weight: bold;
  width: 33%;
}
.HistoryResult{
  font-Size: 16px;
  width: 33%;
}
.ActiveHistoryContainer{
  padding: 20px;
  margin: 10px;
  box-Shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-Radius: 10px;
  min-width: 350px;
  cursor: pointer;
}
.ActiveHistoryTitle{
  font-Size: 18px;
  font-Weight: bold;
  width: 90%;
}
.ActiveHistoryInfo{
  padding-left: 20px;
  padding-bottom: 20px;
  padding-top: 5px;
}
.OverviewContainer{
  margin: 50px;
}
.DetailResult{
  margin-bottom: 30px;
}
.DetailsCreation{
  width: 40%;
}
.DetailsParticipants{
  width: 60%;
}
.DetailsDetailsParticipants{
  border: 1px solid lightgrey;
  padding: 8px;
  margin: 8px 0;
  border-Radius: 4px 
}
.DetailParticipantName{
  width: 50%;
  margin-Left: 15px
}
.DetailParticipantVote{
  width: 50%;
  margin-Right: 15px
}
.VotingInputCreator{
  padding-bottom: 15px;
}
.margin{
  margin: 20px;
}
.Username{
  padding-right: 20px;
  padding-top: 15px;
}

.participantGrid {
  gap: 10px;
  padding: 10px;
}

.participantCard {
  border: 1px solid black;
  padding: 10px;
  min-width: 250px;
  min-height: 300px;
  border-radius: 10px;
  margin: 31px;
}

.participantVoted {
  background-color: rgb(200, 209, 253);
}

.participantCurrent {
  font-weight: bold;
  border: 2px solid black;
}

.votingTitle {
  font-size: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.participantContainer{
  border: 1px solid gray;
  border-radius: 50px;
  width: 65%;
  padding: 20px;
  margin: 20px;
}
.participantName{
  margin: 10px;
}
.participantVote{
  font-size: 90px;
}
.finishVotingButton{
  width: 12%;
  min-height: 60px;
  font-size: 30px;
  border-radius: 10px;
  min-width: 200px
}

.buttonContainer{
  border: 1px solid gray;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 50px;
  width: 65%;
}
.votingButton{
  min-width: 150px;
  min-height: 150px;
  border-radius: 10px;
  font-size: 50px;
  margin-bottom: 20px;
}
.votingInfo{
  padding: 20px;
  padding-bottom: 30px;
}
.resultField{
  min-width: 150px;
  min-height: 150px;
  border-radius: 10px;
  font-size: 50px;
  margin-bottom: 5px;
  border: 1px solid gray;
}
.resultText{
  font-size: 40px;
  padding-left: 50px;
}
.resultVoteText{
  font-size: 25px;
}
.resultTextNumber{
  font-size: 50px;
  padding-left: 50px;
  padding-top: 5px;
}
.spinner{
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
}
.logo{
  width: 75px;
  margin-right: 10px;
  cursor: pointer;
}
.wrap{
  display: flex;
  flex-wrap: wrap;
}
.navigationTextLink{
  cursor: pointer;
}
.Link{
  text-decoration: none;
}
